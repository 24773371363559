import Vue from 'vue';
import {
  Input,
  InputNumber,
  Button,
  Avatar,
  Image,
} from "element-ui"

const components = [
  Input,
  InputNumber,
  Button,
  Avatar,
  Image,
]
components.forEach(item => Vue.use(item))
